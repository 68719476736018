<template>
  <div class="user-complaint">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="list.length > 0 ? '没有更多了': ''"
        @load="getComplaintList"
      >
        <div v-if="list.length > 0 || firstLoading" class="list">
          <div class="box">
            <div v-for="item in list" :key="item.id" class="item" :class="{ over: item.state == '已处理' }">
              <div class="head">
                <div class="icon"><img :src="icon1" alt=""></div>
                <div class="date">{{ item.dealTime | parseTime }}</div>
                <div class="status">{{ item.state }}</div>
              </div>
              <div class="cont">
                <div class="it">
                  <div class="line" />
                  <div class="label">投诉业务员</div>
                  <div class="name">{{ item.businessName }}</div>
                </div>
                <div class="it">
                  <div class="line" />
                  <div class="label">投诉详情</div>
                </div>
                <div class="bottom">
                  {{ item.complaintReason }}
                </div>
                <div v-if="item.serviceReply" class="reply">
                  <div class="hd">
                    <img :src="icon2" alt="">
                    客服回复
                  </div>
                  <div class="ct">
                    {{ item.serviceReply }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="emprty">
          <DefaultPage />
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>
<script>
import icon1 from '@/assets/time@2x.png'
import icon2 from '@/assets/response@2x.png'
import DefaultPage from '@/components/DefaultPage'
import { getComplaintList } from '@/api/user'
import { parseTime } from '@/utils/index'
export default {
  filters: { parseTime },
  components: { DefaultPage },
  data() {
    return {
      list: [],
      params: { limit: 20, page: 1, createTimeSort: 2 },
      count: 0,
      loading: false,
      finished: false,
      refreshing: false,
      firstLoading: true,
      icon1,
      icon2
    }
  },
  methods: {
    onRefresh() {
      // 清空列表数据
      this.finished = false
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.params.page = 1
      this.getComplaintList()
    },
    async getComplaintList() {
      const res = await getComplaintList(this.params)
      if (this.refreshing) {
        this.list = []
        this.page = 1
        this.refreshing = false
      }
      const list = res.datas && res.datas.data || []
      for (let i = 0; i < list.length; i++) {
        const item = { ...list[i] }
        this.list.push(item)
      }
      this.loading = false
      const count = res.datas && res.datas.count
      this.count = count
      if (this.list.length >= count * 1) {
        this.finished = true
      }
      this.params.page += 1
      this.firstLoading = false
    }
  }
}
</script>
<style lang="scss" scoped>
.user-complaint {
  .list {
    .box {
      padding: 10px;
    }
    .item {
      margin-bottom: 10px;
      box-shadow: 0px 10px 12px rgba(0,0,0,0.1);
      border-radius: 8px;
      .head {
        display: flex;
        align-items: center;
        padding: 10px;
        color: #fff;
        border-radius: 8px 8px 0px 0px;
        background: linear-gradient(153deg, #FFB367 0%, #FF7700 100%);
        .icon {
          img {
            display: block;
            width: 15px;
          }
        }
        .date {
          flex: 1;
          padding-left: 5px;
        }
      }
      .cont {
        padding: 10px;
        .it {
          display: flex;
          align-items: center;
          padding: 10px 0;
          font-size: 14px;
          &:first-child {
            border-bottom: 1px solid #F3F3F5;
          }
          .line {
            width: 3px;
            height: 12px;
            background: #CCCCCC;
          }
          .label {
            padding-left: 5px;
            color: #999999;
          }
          .name {
            padding-left: 10px;
          }
        }
      }
      .bottom {
        padding: 10px 0;
      }
      &.over {
        .head {
          background: #CCCCCC;
        }
        .reply {
          padding: 10px;
          background: #F5F5F7;
          .hd {
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #2697FF;
            img {
              display: block;
              width: 19px;
              margin-right: 5px;
            }
          }
          .ct {
            padding-top: 10px;
            color: #666666;
          }
        }
      }
    }
  }
}
</style>
